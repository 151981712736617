import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.css";
import "./styles/header-styles.css";
import "./styles/hero-styles.css";
import TDTest from "./components/TDTestPage";
import Header from "./components/Header";
import MaximoPage from "./components/MaximoPage";
import GeodataPage from "./components/GeodataPage";
import TdAssetCarePage from "./components/TdAssetCarePage";
import HomePage from "./components/HomePage";
import { appTexts } from "./texts/texts";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>{appTexts.title}</title>
        </Helmet>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HomePage />
              </>
            }
          />
          <Route path="/maximo" element={<MaximoPage />} />
          <Route path="/geodata" element={<GeodataPage />} />
          <Route path="/tdassetcare" element={<TdAssetCarePage />} />
          <Route path="/tdtest" element={<TDTest />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
