import React from "react";
import IBM_logo from "../assets/IBM_logo.svg";
import ESRI_logo from "../assets/ESRI_logo.png";
import PED_Logo_RGB_L from "../assets/PED_Logo_RGB_L.svg";
import bpd_logo from "../assets/bpd_logo.svg";
import maxanv_logo from "../assets/maxanv_logo.svg";
import yellow_circle from "../assets/circle.svg";
import { partnerTexts } from "../texts/texts";
import "../styles/partners.css";

const Partners = () => {
  const partnerImages = [
    IBM_logo,
    ESRI_logo,
    PED_Logo_RGB_L,
    bpd_logo,
    maxanv_logo,
  ];

  return (
    <section className="overflow-hidden theme-white" id="partners">
      <div className="PartnerBlock_container__nL2br">
        <div className="PartnerBlock_background__BMaCP">
          <span
            style={{
              boxSizing: "border-box",
              display: "inline-block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: 1,
              border: 0,
              margin: 0,
              padding: 0,
              position: "relative",
              maxWidth: "100%",
            }}
          >
            <span
              style={{
                boxSizing: "border-box",
                display: "block",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: 1,
                border: 0,
                margin: 0,
                padding: 0,
                maxWidth: "100%",
              }}
            >
              <img
                style={{
                  display: "block",
                  maxWidth: "100%",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: 1,
                  border: 0,
                  margin: 0,
                  padding: 0,
                }}
                alt=""
                aria-hidden="true"
                src="data:image/svg+xml,%3csvg%20xmlns=%27http://www.w3.org/2000/svg%27%20version=%271.1%27%20width=%271064%27%20height=%271064%27/%3e"
              />
            </span>
            <img
              alt="Yellow circle"
              role="presentation"
              src={yellow_circle}
              decoding="async"
              data-nimg="intrinsic"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                boxSizing: "border-box",
                padding: 0,
                border: "none",
                margin: "auto",
                display: "block",
                width: 0,
                height: 0,
                minWidth: "100%",
                maxWidth: "100%",
                minHeight: "100%",
                maxHeight: "100%",
              }}
            />
          </span>
        </div>

        <div className="PartnerBlock_intro__hftvr">
          <h2>{partnerTexts.header}</h2>
          <p style={{ fontSize: "2rem" }}>{partnerTexts.introText}</p>
        </div>

        <div className="PartnerBlock_partnerList__eSc1f">
          {partnerTexts.partners.map((partner, index) => (
            <div
              key={partner.name}
              className="PartnerCard_wrapper__uEF8L PartnerCard_size-md__90Neg"
            >
              <div className="PartnerCard_media__c2yb6">
                <div className="PartnerCard_svgWrapper__LJHD9">
                  <a
                    className="PartnerCard_link__ATFiE"
                    tabIndex="-1"
                    href={partner.link}
                  >
                    <img src={partnerImages[index]} alt={partner.alt} />
                  </a>
                </div>
              </div>
              <div className="PartnerCard_text__dpbgP">
                <a href={partner.link}>
                  <p>{partner.description}</p>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Partners;
