import React from "react";
import services01 from "../assets/services01.svg";
import services02 from "../assets/services02.svg";
import services03 from "../assets/services03.svg";
import services04 from "../assets/services04.svg";
import services05 from "../assets/services05.svg";
import services06 from "../assets/services06.svg";
import services07 from "../assets/services07.svg";
import services08 from "../assets/tdtest.svg";
import cloud from "../assets/cloud.svg";
import Footer from "./Footer";
import { maximoServiceTexts } from "../texts/texts";

const images = [
  services01,
  services02,
  services03,
  services04,
  services05,
  services06,
  services07,
  services08,
];

const GeodataPage = () => {
  return (
    <main className="bg-dark">
      <section
        id="maximo-services"
        className="ServiceBlock_services__gTaD_ layout-block theme-white"
        style={{ background: "transparent", marginTop: "10vh" }}
      >
        <div className="ServiceBlock_container__ikEu8">
          <div className="ServiceBlock_intro__FIZTe">
            <h2>{maximoServiceTexts.header}</h2>
          </div>
          <div className="ServiceBlock_serviceList__3PytR">
            {maximoServiceTexts.services.map((service, index) => (
              <div className="ServiceCard_wrapper_noclick" key={index}>
                <div className="ServiceCard_media__f8aI1">
                  <img
                    src={images[index]}
                    width="146.31"
                    height="158.42"
                    alt={service.title}
                  />
                </div>
                <div className="ServiceCard_text__oy4C1">
                  <h3>{service.title}</h3>
                  <p>{service.description}</p>
                </div>
              </div>
            ))}
            <div className="ServiceBlock_media__sKnGJ">
              <img
                src={cloud}
                width="330.455"
                height="209.29"
                alt="Moln illustration"
              />
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default GeodataPage;
