import React, { useEffect, useState } from "react";
import "react-image-lightbox/style.css";
import tdtestlogo from "../assets/TDTEST_logo.svg";
import exempeltest from "../assets/exempel-test.png";
import rapportering from "../assets/rapportering.png";
import automatiserade from "../assets/automatiserade.png";
import speed from "../assets/speed.svg";
import safe from "../assets/safe.svg";
import money from "../assets/money.svg";
import userfriendly from "../assets/user.svg";
import gear from "../assets/gear.svg";
import "../styles/TdTest.css";
import { Link, useNavigate } from "react-router-dom";
import { IconCheck, IconNoCheck } from "../components/Icon";
import { tdTestTexts } from "../texts/texts";
import "../styles/TdTest.css";
import ContactForm from "./ContactForm";

const TdTest = () => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    company: "",
    message: "",
    gdprConsenttdtest: false,
  });

  const handleImageClick = (src) => {
    console.log("Image clicked:", src); // Kontrollera att denna körs
    setSelectedImage(src);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
    }
  };

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      console.warn(`Element med id="${id}" hittades inte.`);
    }
  };

  const handleNavigation = (id) => {
    handleScroll(id);
  };

  const formatTextWithStrong = (text, wordsToBold = []) => {
    const regex = new RegExp(`(${wordsToBold.join("|")})`, "g");
    return text
      .split(regex)
      .map((part, index) =>
        wordsToBold.includes(part) ? <strong key={index}>{part}</strong> : part
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://totaldigital.info/public/send_email.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      )
        .then((response) => response.json())
        .then((data) => console.log(data))
        .catch((error) => console.error("Error:", error));

      const result = await response.json();
      if (result.success) {
        alert("Meddelandet har skickats!");
      } else {
        alert("Ett fel uppstod: " + result.message);
      }
    } catch (error) {
      alert("Ett fel uppstod vid anslutningen till servern.");
    }
  };

  return (
    <body className="tdtest">
      <div className="theme-default">
        <section id="Hero" className="layout-block theme-tdtest">
          <div className="HeroCard_wrapper__4Q5aw HeroCard_textCentered__HtQ96">
            <h1 className="HeroCard_headingText__ByzEW">
              {formatTextWithStrong(tdTestTexts.hero.header)}
            </h1>
            <div className="HeroCard_copy__HvA1B">
              <p className="hero">
                {formatTextWithStrong(tdTestTexts.hero.description)}
              </p>
              <div style={{ marginTop: "2.5rem" }}>
                <a href="/#kontakta-oss">
                  <button type="button">{tdTestTexts.hero.buttonText}</button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section
          id="vadartdtest"
          className="TextMediaBlock_wrapper__fd2J4 theme-tdtest TextMediaBlock_tdtest__9j60y"
        >
          <div className="TextMediaBlock_content__F8JY_ TextMediaBlock_media-right__34Pzp">
            <div className="TextMediaBlock_text__gFT5_">
              <div>
                <h2>{formatTextWithStrong(tdTestTexts.about.header)}</h2>
                {tdTestTexts.about.paragraphs.map((para, index) => (
                  <p key={index}>{formatTextWithStrong(para)}</p>
                ))}
              </div>
            </div>
            <div className="TextMediaBlock_media__PqTtW undefined">
              <div className="TextMediaBlock_mediaInner__TNera">
                <img
                  alt="Pilar"
                  src={tdtestlogo}
                  decoding="async"
                  data-nimg="fill"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    boxSizing: "border-box",
                    padding: 0,
                    border: "none",
                    margin: "auto",
                    display: "block",
                    width: 0,
                    height: 0,
                    minWidth: "100%",
                    maxWidth: "100%",
                    minHeight: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section id="cards" className="layout-block theme-tdtest">
          <div className="CardBlock_content__jIHkV">
            <div className="CardBlock_intro___qsn_">
              <h2>
                {formatTextWithStrong(tdTestTexts.sections.capabilities.header)}
              </h2>
            </div>
            <div className="CardBlock_cardList__JJb4I">
              {tdTestTexts.sections.capabilities.items.map((item, index) => (
                <div className="Card_wrapper__lHSgL" key={index}>
                  <div>
                    <div className="Card_heading__rgi0j">
                      <h3>{formatTextWithStrong(item.title)}</h3>
                    </div>
                    <div className="Card_text__C_ZBJ">
                      {/* Visa första raden som underrubrik med vissa ord i fetstil */}
                      <p className="Card_subtitle">
                        {formatTextWithStrong(item.content[0], ["TD Test"])}
                      </p>
                      {/* Rendera resten som lista med specifika ord i fetstil */}
                      <ul>
                        {item.content.slice(1).map((content, idx) => (
                          <li key={idx}>
                            {formatTextWithStrong(content, [
                              "gränssnitt",
                              "Återrapportering",
                              "historik",
                              "testdata",
                              "databasisolering",
                              "Logghantering",
                              "affärsobjekten",
                            ])}
                          </li>
                        ))}
                      </ul>
                    </div>

                    {/* Bildvisning och andra komponenter */}
                    {index === 2 && (
                      <div className="Card_image__Kj4yP">
                        <img
                          src={rapportering}
                          alt="Rapportering"
                          className="responsive-image"
                          onClick={() => handleImageClick(rapportering)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                    {index === 3 && (
                      <div className="Card_image__Kj4yP">
                        <img
                          src={automatiserade}
                          alt="Automatiserade tester"
                          className="responsive-image"
                          onClick={() => handleImageClick(automatiserade)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section
          id="funktioner"
          className="undefined layout-block theme-tdtest"
        >
          <div className="CardBlock_content__jIHkV">
            <div className="CardBlock_intro___qsn_">
              <h2>{tdTestTexts.sections.features.header}</h2>
            </div>
            <div className="CardBlock_cardList__JJb4I">
              {tdTestTexts.sections.features.items.map((feature, index) => (
                <div className="Card_wrapper__lHSgL" key={index}>
                  <div className="Card_iconContainer__u2azb">
                    <img
                      className="Card_icon-small__gI2uH responsive-image"
                      src={
                        index === 0
                          ? gear
                          : index === 1
                          ? speed
                          : index === 2
                          ? safe
                          : index === 3
                          ? money
                          : userfriendly
                      }
                      alt={`${feature.title} icon`}
                    />
                  </div>
                  <div>
                    <div className="Card_heading__rgi0j">
                      <h3>{feature.title}</h3>
                    </div>

                    {/* Speciell hantering för "Användarvänligt" och "Exempel på test" */}
                    <div className="Card_text__C_ZBJ">
                      {feature.title === "Användarvänligt" ? (
                        <>
                          <p>
                            <strong>{tdTestTexts.TDTest}</strong>{" "}
                            {feature.description1}
                          </p>
                          <ul>
                            <li>{feature.description2}</li>
                            <li>{feature.description3}</li>
                            <li>{feature.description4}</li>
                            <li>{feature.description5}</li>
                            <li>{feature.description6}</li>
                            <li>{feature.description7}</li>
                          </ul>
                        </>
                      ) : feature.title === "Exempel på test" ? (
                        <div
                          className="Card_image__Kj4yP"
                          style={{ paddingLeft: "0px !important" }}
                        >
                          <img
                            src={exempeltest}
                            alt="Exempel på test"
                            onClick={() => handleImageClick(exempeltest)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ) : (
                        <p>{feature.description}</p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="Jämförelse" className="layout-block theme-tdtest">
          <div className="PromoTable_promotableContainer__jFDKt">
            <div className="PromoTable_promotableTitles__1VupB">
              <h3>{tdTestTexts.comparison.header}</h3>
            </div>
            <div className="PromoTable_promotableHeader__66tjS">
              {tdTestTexts.comparison.columns.map((column, index) => (
                <div key={index} className="">
                  <p>{column}</p>
                </div>
              ))}
            </div>
            <div className="PromoTable_promotableRows__xo0gj">
              {tdTestTexts.comparison.features.map((feature, index) => (
                <div key={index} className="PromoTable_promoTableRow__q2qeP">
                  <div className="">
                    <p>{feature.description}</p>
                  </div>
                  <div className="">
                    {feature.webdriver ? <IconCheck /> : <IconNoCheck />}
                  </div>
                  <div className="">
                    {feature.tdTest ? <IconCheck /> : <IconNoCheck />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="kontakta-oss">
          <ContactForm />
        </section>

        <footer className="theme-default">
          <div className="FooterContainer_outer__yU79X FooterContainer_themePadding__gqMAx FooterContainer_width-full__e6H7j">
            <div className="FooterContainer_boxed___I9um FooterContainer_themePadding__gqMAx">
              <div className="Footer_wrapper__1C_mQ">
                <div className="Footer_intro__PyWb1">
                  <p className="preamble">
                    TD Test är en del av Total Digitals tjänsteutbud. Oavsett
                    vilken av våra tjänster ni vill ha hjälp med erbjuder vi
                    stöd genom hela processen. För att komma i kontakt med oss
                    kan ni nå oss på följande:
                  </p>
                </div>
                <div className="Footer_footerList__F8_nT">
                  <div className="FooterCard_card__M0fsl">
                    <a
                      tabIndex="-1"
                      href="/cdn-cgi/l/email-protection#254c4b434a65514a514449414c424c5144490b5640"
                    >
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/icon-email.svg"
                          alt="https://admin.tdtest.se/media/icon-email.svg"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="/cdn-cgi/l/email-protection#244d4a424b64504b504548404d434d5045480a5741">
                        <p>
                          <span>
                            E-post: <br />
                            <span
                              className="__cf_email__"
                              data-cfemail="caa3a4aca58abea5beaba6aea3ada3beaba6e4b9af"
                            >
                              [email&#160;protected]
                            </span>
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="FooterCard_card__M0fsl">
                    <a tabIndex="-1" href="tel:0101010070">
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/ic_round-phone.svg"
                          alt="https://admin.tdtest.se/media/ic_round-phone.svg"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="tel:0101010070">
                        <p>
                          <span>
                            Telefon: <br />
                            010 - 10 100 70
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="FooterCard_card__M0fsl">
                    <a
                      tabIndex="-1"
                      href="https://www.linkedin.com/company/total-digital-it-solutions/about/"
                    >
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/icon-linkedin.svg"
                          alt="https://admin.tdtest.se/media/icon-linkedin.svg"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="https://www.linkedin.com/company/total-digital-it-solutions/about/">
                        <p>
                          <span>Total Digital på Linkedin</span>
                        </p>
                      </a>
                    </div>
                  </div>
                  <div className="FooterCard_card__M0fsl">
                    <a tabIndex="-1" href="/" onClick={handleLogoClick}>
                      <div className="FooterCard_media__09_qg">
                        <img
                          src="https://admin.tdtest.se/media/TotalDigital_WhiteLogo-1.png"
                          alt="https://admin.tdtest.se/media/TotalDigital_WhiteLogo-1.png"
                        />
                      </div>
                    </a>
                    <div className="FooterCard_text__t124i">
                      <a href="/" onClick={handleLogoClick}>
                        <p>
                          <span>Total Digital hemsida</span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>

        {modalOpen && (
          <div className="modal-overlay" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <button className="modal-close-button" onClick={closeModal}>
                X
              </button>
              <img src={selectedImage} alt="Visning" />
            </div>
          </div>
        )}
      </div>
    </body>
  );
};

export default TdTest;
