import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../assets/Total_Digital_Logo.svg";
import { headerTexts } from "../texts/texts";
import "../styles/header.css";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [scrolled, setScrolled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleNavigation = (id) => {
    if (!isMobile) {
      if (window.location.pathname === "/") {
        handleScroll(id);
      } else {
        navigate("/#" + id);
        setTimeout(() => handleScroll(id), 0);
      }
    } else {
      handleScroll(id);
    }
  };

  const toggleSubMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const menuItem = e.target.closest(".MenuItem");
    menuItem.classList.toggle("active");
  };

  const handleScrollEvent = () => {
    const heroSection = document.querySelector(".hero-section");
    if (heroSection) {
      setScrolled(window.scrollY > heroSection.offsetHeight - 100);
    }
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    if (window.location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      navigate("/");
      setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 0);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollEvent);
    return () => window.removeEventListener("scroll", handleScrollEvent);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
    setScrolled(false);
  }, [location]);

  const renderLinks = () => {
    const isTdTestPage = location.pathname === "/tdtest";
    const menuItems = isTdTestPage
      ? headerTexts.menuItems.tdtest
      : headerTexts.menuItems.main;
    const subMenuItems = headerTexts.menuItems.subMenu;

    return (
      <>
        {menuItems.map((item) => (
          <li key={item.id} className="MenuItem">
            <a
              href={`#${item.id}`}
              onClick={(e) => {
                e.preventDefault();
                isTdTestPage
                  ? handleScroll(item.id)
                  : handleNavigation(item.id);
              }}
              className={scrolled ? "black" : "white"}
            >
              {item.label}
              {item.id === "services" && !isTdTestPage && (
                <span className="arrow-icon" onClick={toggleSubMenu}>
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 24 24"
                    height="20"
                    width="20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M7 10l5 5 5-5z"></path>
                  </svg>
                </span>
              )}
            </a>
            {item.id === "services" && !isTdTestPage && (
              <ul className="SubMenu">
                {subMenuItems.map((subItem) => (
                  <li key={subItem.link}>
                    <Link to={subItem.link}>{subItem.label}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </>
    );
  };

  return (
    <header className={`HeaderContainer ${scrolled ? "scrolled" : ""}`}>
      <div className="Header_logoWrapper">
        <a href="/" onClick={handleLogoClick} className="Header_logoLink">
          <img
            src={logo}
            alt={headerTexts.logoAlt}
            className={`Header_logo ${scrolled ? "black" : "white"}`}
          />
        </a>
      </div>
      <nav className="Menu">
        <ul className="MenuItems">{renderLinks()}</ul>
      </nav>
    </header>
  );
};

export default Header;
