import React from "react";
import iconemail from "../assets/icon-email.svg";
import iconphone from "../assets/icon-phone.svg";
import iconlinkedin from "../assets/icon-linkedin.svg";
import { footerTexts } from "../texts/texts";
import "../styles/footer.css";

const Footer = () => {
  const icons = [iconemail, iconphone, iconlinkedin];

  return (
    <footer className="theme-rust">
      <div className="FooterContainer_outer__yU79X FooterContainer_themePadding__gqMAx FooterContainer_width-large__LIHVn">
        <div className="Footer_wrapper__1C_mQ">
          <div className="Footer_intro__PyWb1">
            <p className="preamble">{footerTexts.intro}</p>
          </div>
          <div className="Footer_footerList__F8_nT Footer_cols-3__S_BW7">
            {footerTexts.contacts.map((contact, index) => (
              <div key={index} className="FooterCard_card__M0fsl">
                <a tabIndex="-1" href={contact.link}>
                  <div className="FooterCard_media__09_qg">
                    <img src={icons[index]} alt={`Ikon för ${contact.type}`} />
                  </div>
                </a>
                <div className="FooterCard_text__t124i">
                  <a href={contact.link}>
                    <p>
                      <span>
                        {contact.type}: <br />
                        {contact.value}
                      </span>
                    </p>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
