import React from "react";
import { aboutTexts } from "../texts/texts";
import "../styles/about.css";
import MapComponent from "./MapComponent";

const About = () => {
  return (
    <section
      id="om-total-digital"
      className="TextMediaBlock_wrapper__fd2J4 theme-lightGrey undefined"
    >
      <div
        className="TextMediaBlock_content__F8JY_ TextMediaBlock_media-right__34Pzp"
        style={{ marginBottom: "4rem" }}
      >
        <div className="about-textblock">
          <div>
            <h2 style={{ fontSize: "50px" }}>{aboutTexts.header}</h2>
            {aboutTexts.content.map((paragraph, index) => (
              <div style={{ paddingBottom: "1.5rem" }} key={index}>
                <div style={{ fontSize: "1.4rem" }}>{paragraph}</div>
              </div>
            ))}
          </div>
        </div>
        <div
          className=""
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
          }}
        >
          <MapComponent />
        </div>
      </div>
    </section>
  );
};

export default About;
