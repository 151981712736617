import React, { useState, useEffect } from "react";
import "../styles/servicesStyle.css";
import services01 from "../assets/services01.svg";
import services02 from "../assets/services02.svg";
import services03 from "../assets/services03.svg";
import services04 from "../assets/services04.svg";
import services05 from "../assets/services05.svg";
import services06 from "../assets/services06.svg";
import services07 from "../assets/services07.svg";
import rails from "../assets/ralisar.svg";
import { serviceTexts } from "../texts/texts";

interface ServiceCardProps {
  title: string;
  description: string;
  imageSrc: string;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  imageSrc,
}) => {
  const [flipped, setFlipped] = useState(false);

  const handleCardClick = () => {
    setFlipped(!flipped);
  };

  return (
    <div
      className={`customServiceCard_wrapper ${flipped ? "flipped" : ""}`}
      onClick={handleCardClick}
    >
      <div className="customServiceCard_inner">
        <div className="customServiceCard_front">
          <div className="customServiceCard_media">
            <img src={imageSrc} alt={title} />
          </div>
          <div className="customServiceCard_text">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="customServiceCard_back">
          <div className="customServiceCard_text">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

function Services() {
  const [columnCount, setColumnCount] = useState(2);

  useEffect(() => {
    function handleResize() {
      setColumnCount(window.innerWidth > 1600 ? 2 : 1);
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const serviceCards = serviceTexts.serviceDescriptions.map((text, index) => ({
    ...text,
    imageSrc: [
      services01,
      services04,
      rails,
      services02,
      services03,
      services06,
      services07,
      services05,
    ][index],
  }));

  return (
    <section
      id="services"
      className="customServiceBlock layout-block theme-white"
    >
      <div className="customServicesContainer">
        <div className="customServicesContent">
          <div className="customServicesHeader">
            <h2>{serviceTexts.header}</h2>
            <br />
          </div>
          <div
            className="customServicesList"
            style={{
              columnCount: columnCount,
            }}
          >
            {serviceCards.map((card, index) => (
              <ServiceCard
                key={index}
                title={card.title}
                description={card.description}
                imageSrc={card.imageSrc}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
