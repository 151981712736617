import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image1 from "../assets/backgrounds/maximo22.png";
import image2 from "../assets/backgrounds/maximo44.png";
import image3 from "../assets/backgrounds/sthlm22.png";
import image4 from "../assets/backgrounds/sthlm33.png";
import tt_arrows from "../assets/tt_arrows.svg";
import backgroundImageVertical from "../assets/sthlm1.png";
import { heroTexts } from "../texts/texts";

const images = [
  { src: image1, text: "Geografiska tjänster", link: "/geodata" },
  { src: image2, text: "TD Asset Care", link: "/tdassetcare" },
  { src: image3, text: "TD Test", link: "/tdtest" },
  { src: image4, text: "Maximo tjänster", link: "/maximo" },
];

const HeroSection = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      <section className="hero-section">
        <div className="rotating-images">
          {images.map((image, index) => (
            <div
              key={index}
              className={`rotating-image ${
                index === currentImageIndex ? "active" : ""
              }`}
              style={{
                backgroundImage: `linear-gradient(to right, rgba(0,0,0,0.8), rgba(0,0,0,0)), url(${image.src})`,
              }}
            ></div>
          ))}
        </div>

        <div className="text-overlay">
          <h1>{images[currentImageIndex].text}</h1>
          <div>
            <Link to={images[currentImageIndex].link}>
              <button type="button">{heroTexts.buttonText}</button>
            </Link>
          </div>
        </div>
      </section>

      <section className="erbjuder">
        <h2 style={{ textAlign: "center", color: "black" }}>
          {heroTexts.erbjuderTitle}
        </h2>
        <div className="content-container">
          <div className="text-content">
            <p>{heroTexts.erbjuderText1}</p>
          </div>
          <div className="image-content">
            <img src={tt_arrows} alt="Pilar" />
          </div>
          <div className="text-content">
            <p>{heroTexts.erbjuderText2}</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HeroSection;
